#about, #application {
  margin-top: $medium-space;
  position: relative;

  .section-heading {
    margin-bottom: $small-space;
  }

  $about-max-width: $max-width - 160px;

  .container {
    max-width: $about-max-width;
    margin: 0 auto;
    color: $light-gray;
    @media screen and (max-width: $max-width) {
      margin: 0 $large-space;
    }

    .section-intro {
      margin: $medium-space 0 0;
      padding: 0;
    }

    .side-image {
      max-width: 200px;
      padding-left: 20px;
      &.pull-right {
        float: right;
      }
    }

    p.content-text {
      font-size: $medium-font-size;
      margin: $small-space 0;

      &.apply {
        text-align: center;

        .apply-button {
          display: inline-block;
          margin: 0 auto;
        }
      }
    }

    .about-video {
      margin: 0 auto;
      width: 100%;
      max-width: 640px;
      border-radius: 1rem;
      overflow: hidden;
      transition: $transition-speed all;
      cursor: pointer;

      &:hover {
        transform: scale(1.05);
      }

      & > div {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .intro {
    padding: $medium-space 0;
    font-size: $subheading-font-size;
    text-align: center;
    font-weight: $heavy-font-weight;
  }

  .body {
    margin: $medium-space 0;
    font-size: $medium-font-size;
    text-align: center;

    @media screen and (min-width: $max-width) {
      text-align: justify; 
    }
  }
}

