#sponsors.container {
  position: relative;

  @include flex(column nowrap, flex-start, center);
  margin: $large-space $small-space;
  min-height: 300px;

  .section-heading {
    margin-bottom: $small-space;
    padding-top: 100px;
  }

  #sub-heading {
    margin-bottom: 1.5rem;
  }

  @media screen and (max-width: 750px) {
    width: 100%;
    margin-left: 0px;
  }

  .level {
    @include flex(row wrap, center, center);
    width: 70%;
    margin: 1rem auto;

    img:hover {
      transition: $transition-speed;
      transform: scale(1.05);
    }

    &.level-biggest img {
      width: 100%;
    }

    &.level-biggest a {
      width: 60%;
      min-width: 250px;
    }

    &.level-big {
      margin-top: 2.5rem;
      margin-bottom: 3.5rem;
    }

    &.level-big img {
      width: 100%;
    }

    &.level-big a {
      width: 25%;
      min-width: 100px;
    }

    &.level-medium {
      margin-bottom: 0.5rem;
    }

    &.level-medium img {
      width: 100%;
    }

    &.level-medium a {
      width: 20%;
      min-width: 70px;
      margin: 0 10px;
    }

    &.level-small img {
      max-height: 50px;
      max-width: 100%;
    }

    &.level-small a {
      max-width: 13%;
      margin: 0 5px;
    }

    &.level-smallest {
      margin-top: 2rem;
    }

    &.level-smallest img {
      width: 100%;
    }

    &.level-smallest a {
      width: 10%;
      min-width: 50px;
      margin: 0 10px;
    }

    &.level-tiny {
      margin-bottom: 3rem;
    }

    &.level-tiny img {
      width: 100%;
    }

    &.level-tiny a {
      width: 6%;
      min-width: 50px;
      margin: 0 10px;
    }

    a {
      margin: 0rem;
    }

    & + .level {
    }
  }
}
