@import url(https://cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css);
/* Color Palette */
/* Color Palette 2021 */
/* Font Styles */
/* Base Styles */
.logo-text-tree {
  font-family: proxima-nova, sans-serif; }

.logo-text-hacks {
  font-family: proxima-nova, sans-serif;
  font-weight: 700; }

h1.section-heading {
  font-size: 48px;
  text-transform: uppercase;
  text-align: center; }
  @media screen and (max-width: 1023px) {
    h1.section-heading {
      padding: 3rem 0 1.5rem;
      font-size: 36px; } }

h2.section-subheading {
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold; }

.apply-button {
  border: 2px solid #00000000;
  box-sizing: border-box;
  background-color: #f46e20;
  color: white;
  display: block;
  font-weight: 700;
  font-size: 28px;
  padding: 1vh 0;
  text-align: center;
  -webkit-transition: all 0.2s;
  transition: all 0.2s; }
  .apply-button.subtle {
    background: transparent;
    color: #fff;
    font-size: 17.6px;
    border: 2px solid #00e174; }

body {
  background-color: #edf5ee;
  color: black;
  font-family: proxima-nova, sans-serif;
  font-size: 16px; }

#root {
  overflow-x: hidden; }

body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
nav,
button {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700; }

a {
  color: black;
  text-decoration: none; }
  a:hover {
    cursor: pointer; }

#navbar {
  position: fixed;
  top: 0;
  z-index: 101;
  background-color: #ffffff;
  height: 80px;
  width: 100vw;
  box-shadow: 0 5px 30px -5px rgba(0, 0, 0, 0.2); }
  #navbar .container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0 2vw;
    list-style-type: none; }

#navbar-logo {
  flex: 1 1;
  margin-top: -0.5vh;
  font-size: 36px;
  padding: 0 15px 0 15px; }
  #navbar-logo a {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center; }
  #navbar-logo img {
    width: 45px;
    height: 40px; }

.menu {
  width: 100%;
  max-width: 1300px;
  display: flex;
  align-items: center;
  list-style-type: none;
  text-align: center; }
  .menu li {
    display: inline-block;
    vertical-align: middle; }

.navbar-item {
  margin: 10px 0.5vw;
  padding: 0 15px 0 15px;
  font-size: 18.88px; }
  .navbar-item a {
    color: #696e76; }
  .navbar-item:hover a {
    color: #309071; }

.special-link a {
  color: #f46e20; }

.apply-link a {
  color: #ffffff;
  background-color: #309071;
  padding: 3px 6px;
  font-weight: bold;
  border-radius: 0.25rem;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
  display: inline-block; }
  .apply-link a:hover, .apply-link a:focus {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    color: #fff; }

#navbar-apply {
  margin-left: 0.5vw;
  padding: 15px 0.75vw;
  font-size: 18.88px;
  font-weight: 700;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s; }
  #navbar-apply:hover {
    background-color: #00e174; }

#title {
  color: #309071;
  margin-left: 1vw; }

@media screen and (max-width: 1220px) {
  .menu {
    display: block; }
  .navbar-item {
    margin: 0.5vw; }
  #navbar-logo {
    display: none; }
  .navbar-item.about-link {
    display: none; } }

.apply-button {
  margin-top: 1vw;
  background-color: #309071;
  padding: 0.5vh 2vw 0.5vh 2vw;
  border-radius: 5px; }
  .apply-button:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }

#home {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center; }
  @media screen and (min-width: 450px) {
    #home {
      height: 100vh;
      width: 100vw;
      min-height: calc(500px + 45vh);
      padding-top: 20vh; } }
  @media screen and (max-width: 450px) {
    #home {
      margin: 7.5vw 0; } }
  #home img {
    max-height: 35vh;
    max-width: 80vw; }
  @media screen and (max-width: 450px) {
    #home {
      padding-top: 15vh; }
      #home img {
        margin-bottom: 25px; } }
  #home .logo {
    min-height: 45vh; }
    @media screen and (max-width: 750px) {
      #home .logo {
        min-height: 20vh; } }
  #home #arm {
    height: 14vh;
    margin-right: auto;
    margin-left: -1vw; }
    @media screen and (max-width: 450px) {
      #home #arm {
        height: 7vh; } }
  #home h1 {
    font-size: 8vh;
    font-weight: normal; }
  #home h2 {
    font-size: 22.4px;
    font-weight: 700;
    color: #a7dde8;
    max-width: 80vw; }
    #home h2.ended {
      font-style: italic; }
    @media screen and (max-width: 750px) {
      #home h2 {
        text-align: center; } }
  #home p {
    font-size: 25px; }
  #home #date-text {
    max-width: 90vw;
    text-align: center;
    color: #309071;
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 1vh; }

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

.scale-in-center {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.slide-in-left {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

#countdown {
  text-align: center; }
  #countdown img {
    margin: 10vw 0 5vw 0;
    width: 35vw;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  #countdown sup {
    vertical-align: 25%; }

#deadlines {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: flex-end;
  align-items: center; }
  @media screen and (max-width: 550px) {
    #deadlines {
      margin: 0 -14vw; } }

.single-deadline {
  color: #696e76;
  margin: -1vw;
  padding: 1vw;
  -webkit-transition: 0.2s all;
  transition: 0.2s all; }
  .single-deadline h1 {
    text-decoration: none !important;
    color: #e51b5d;
    font-size: 96px; }
    @media screen and (max-width: 650px) {
      .single-deadline h1 {
        font-size: 43.2px; } }
  .single-deadline p {
    font-size: 18px; }
    @media screen and (max-width: 650px) {
      .single-deadline p {
        font-size: 12.6px; }
        .single-deadline p .deadline-title {
          display: block; } }
  .single-deadline .subtext {
    font-weight: bold;
    color: #e51b5d; }
  .single-deadline .large {
    text-transform: uppercase;
    font-size: 23px; }
  .single-deadline .days, .single-deadline .day {
    text-transform: lowercase; }
  .single-deadline .elapsed {
    color: #696e76;
    text-decoration: line-through; }
  @media screen and (min-width: 551px) {
    .single-deadline:hover {
      box-shadow: 0px 0px 15px 0px #e4e4e4;
      border-radius: 20px;
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      background-color: #fff; } }

.elapsed:hover {
  box-shadow: none;
  -webkit-transform: none;
          transform: none;
  background-color: #edf5ee; }

#challenges {
  position: relative;
  margin: 7.5vw 0; }
  #challenges .section-heading {
    padding-top: 100px;
    margin-bottom: 2vw; }
  #challenges .section-intro {
    color: #696e76;
    font-size: 18px;
    width: 960px;
    max-width: 80vw;
    margin: 2vw auto; }
  #challenges .container {
    color: #696e76;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start; }
    @media screen and (max-width: 750px) {
      #challenges .container {
        display: block;
        width: 100%; } }

.challenge {
  background-color: #fff;
  box-shadow: 0px 0px 15px 0px #e4e4e4;
  margin: 1vw;
  border-radius: 20px;
  width: 27vw;
  min-width: 300px;
  padding: 2vw;
  filter: alpha(opacity=60);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-self: stretch;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  cursor: auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center; }
  .challenge:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  @media screen and (max-width: 750px) {
    .challenge {
      display: block;
      width: 80%;
      margin: 0px auto;
      margin-top: 30px;
      padding: 7.5vw; } }
  .challenge img {
    margin-bottom: 2vw;
    display: block;
    width: 50%;
    margin-left: auto;
    margin-right: auto; }
  .challenge h1 {
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 2vw;
    font-weight: 700; }
  .challenge p {
    font-size: 18px;
    text-align: center; }

#about, #application {
  margin-top: 5vh;
  position: relative; }
  #about .section-heading, #application .section-heading {
    margin-bottom: 2vw; }
  #about .container, #application .container {
    max-width: 800px;
    margin: 0 auto;
    color: #696e76; }
    @media screen and (max-width: 960px) {
      #about .container, #application .container {
        margin: 0 7.5vw; } }
    #about .container .section-intro, #application .container .section-intro {
      margin: 5vh 0 0;
      padding: 0; }
    #about .container .side-image, #application .container .side-image {
      max-width: 200px;
      padding-left: 20px; }
      #about .container .side-image.pull-right, #application .container .side-image.pull-right {
        float: right; }
    #about .container p.content-text, #application .container p.content-text {
      font-size: 18px;
      margin: 2vw 0; }
      #about .container p.content-text.apply, #application .container p.content-text.apply {
        text-align: center; }
        #about .container p.content-text.apply .apply-button, #application .container p.content-text.apply .apply-button {
          display: inline-block;
          margin: 0 auto; }
    #about .container .about-video, #application .container .about-video {
      margin: 0 auto;
      width: 100%;
      max-width: 640px;
      border-radius: 1rem;
      overflow: hidden;
      -webkit-transition: 0.2s all;
      transition: 0.2s all;
      cursor: pointer; }
      #about .container .about-video:hover, #application .container .about-video:hover {
        -webkit-transform: scale(1.05);
                transform: scale(1.05); }
      #about .container .about-video > div, #application .container .about-video > div {
        position: relative;
        padding-bottom: 56.25%;
        /* 16:9 */
        height: 0; }
        #about .container .about-video > div iframe, #application .container .about-video > div iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%; }
  #about .intro, #application .intro {
    padding: 5vh 0;
    font-size: 24px;
    text-align: center;
    font-weight: 700; }
  #about .body, #application .body {
    margin: 5vh 0;
    font-size: 18px;
    text-align: center; }
    @media screen and (min-width: 960px) {
      #about .body, #application .body {
        text-align: justify; } }

#projects {
  position: relative; }
  #projects .section-heading {
    padding-top: 100px;
    margin-bottom: 2vw; }
  #projects .container {
    max-width: 960px;
    margin: 7.5vw auto; }
  @media screen and (max-width: 750px) {
    #projects .grid-item {
      width: 68vw; } }

.slick-track {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center; }

.grid-item {
  box-shadow: 0px 0px 15px 0px #e4e4e4;
  border-radius: 20px;
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
  color: white;
  padding: 2vw;
  margin: 3vh auto;
  cursor: pointer;
  width: 26vw;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center; }
  .grid-item:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  .grid-item p {
    text-align: center;
    margin: 0 1vw; }
  .grid-item .title {
    font-weight: bold;
    font-size: 24px;
    text-transform: uppercase;
    opacity: 1;
    margin: 2vw 0; }
  .grid-item .text {
    font-size: 18px;
    margin-bottom: 2vw; }

#faq {
  position: relative;
  margin: 7.5vw 2vw;
  width: 70vw;
  margin: auto;
  font-size: 1.1rem; }
  #faq .section-heading {
    margin-bottom: 2vw; }
  #faq .faq-plus {
    margin-right: 20px;
    width: 30px;
    height: 30px; }
  #faq .section-subheading {
    margin-bottom: 25px; }
  #faq .faq-question, #faq .faq-answer {
    text-align: left; }
  #faq .faq-question {
    display: flex;
    margin-bottom: 10px;
    align-items: center; }
  #faq .faq-answer {
    margin-bottom: 15px;
    color: #696e76;
    -webkit-transition: max-height .5s ease-in-out;
    transition: max-height .5s ease-in-out;
    overflow: hidden; }
  #faq .faq-container {
    margin: -1.6vw;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start; }
  #faq .faq-item {
    padding: 1.6vw;
    flex-basis: 50%; }
    @media screen and (max-width: 1023px) {
      #faq .faq-item {
        flex-basis: 100%; } }

#sponsors.container {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 7.5vw 2vw;
  min-height: 300px; }
  #sponsors.container .section-heading {
    margin-bottom: 2vw;
    padding-top: 100px; }
  #sponsors.container #sub-heading {
    margin-bottom: 1.5rem; }
  @media screen and (max-width: 750px) {
    #sponsors.container {
      width: 100%;
      margin-left: 0px; } }
  #sponsors.container .level {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin: 1rem auto; }
    #sponsors.container .level img:hover {
      -webkit-transition: 0.2s;
      transition: 0.2s;
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
    #sponsors.container .level.level-biggest img {
      width: 100%; }
    #sponsors.container .level.level-biggest a {
      width: 60%;
      min-width: 250px; }
    #sponsors.container .level.level-big {
      margin-top: 2.5rem;
      margin-bottom: 3.5rem; }
    #sponsors.container .level.level-big img {
      width: 100%; }
    #sponsors.container .level.level-big a {
      width: 25%;
      min-width: 100px; }
    #sponsors.container .level.level-medium {
      margin-bottom: 0.5rem; }
    #sponsors.container .level.level-medium img {
      width: 100%; }
    #sponsors.container .level.level-medium a {
      width: 20%;
      min-width: 70px;
      margin: 0 10px; }
    #sponsors.container .level.level-small img {
      max-height: 50px;
      max-width: 100%; }
    #sponsors.container .level.level-small a {
      max-width: 13%;
      margin: 0 5px; }
    #sponsors.container .level.level-smallest {
      margin-top: 2rem; }
    #sponsors.container .level.level-smallest img {
      width: 100%; }
    #sponsors.container .level.level-smallest a {
      width: 10%;
      min-width: 50px;
      margin: 0 10px; }
    #sponsors.container .level.level-tiny {
      margin-bottom: 3rem; }
    #sponsors.container .level.level-tiny img {
      width: 100%; }
    #sponsors.container .level.level-tiny a {
      width: 6%;
      min-width: 50px;
      margin: 0 10px; }
    #sponsors.container .level a {
      margin: 0rem; }

#footer {
  position: relative;
  text-align: center; }
  #footer #footerImg {
    width: 100%;
    opacity: 0.8;
    background-image: url(/static/media/main-quad.2d51a250.svg);
    background-size: cover;
    background-position-x: center;
    height: 30.52vw; }

.footer-item {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin: 0 15px 20px 0px;
  -webkit-transition: 0.2s;
  transition: 0.2s; }
  .footer-item:nth-child(5) {
    margin-right: 0; }
  .footer-item:hover {
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05); }
  .footer-item img {
    width: 100%; }

.emailSignup form {
  padding: 3vh 0;
  margin-bottom: 1rem; }
  .emailSignup form div[aria-hidden="true"] {
    position: absolute;
    left: -5000px; }
  .emailSignup form #mce-EMAIL {
    background-color: inherit;
    border: none;
    border-bottom: 1.5px solid black;
    border-radius: 0;
    color: black;
    width: 400px;
    max-width: 80vw;
    display: block;
    font-family: proxima-nova, sans-serif;
    font-size: 14pt; }
    .emailSignup form #mce-EMAIL::-webkit-input-placeholder {
      color: #696e76;
      opacity: 1; }
    .emailSignup form #mce-EMAIL::-moz-placeholder {
      color: #696e76;
      opacity: 1; }
    .emailSignup form #mce-EMAIL:-ms-input-placeholder {
      color: #696e76;
      opacity: 1; }
    .emailSignup form #mce-EMAIL::-ms-input-placeholder {
      color: #696e76;
      opacity: 1; }
    .emailSignup form #mce-EMAIL::placeholder {
      color: #696e76;
      opacity: 1; }
  .emailSignup form #mce-EMAIL:focus {
    outline: 0; }
  .emailSignup form #mc-embedded-subscribe {
    display: block;
    margin-top: 1rem;
    font-size: 1rem;
    width: 100%;
    max-width: 80vw;
    cursor: pointer;
    -webkit-appearance: none;
    white-space: normal; }

::-webkit-input-placeholder {
  color: #e0e0e0;
  font-style: italic; }

::-moz-placeholder {
  color: #e0e0e0;
  font-style: italic; }

:-ms-input-placeholder {
  color: #e0e0e0;
  font-style: italic; }

::-ms-input-placeholder {
  color: #e0e0e0;
  font-style: italic; }

::placeholder {
  color: #e0e0e0;
  font-style: italic; }

body {
  overflow-x: hidden; }

@media screen and (max-width: 1023px) {
  .stripe, .stripe-wrapper {
    display: none; } }

.stripe {
  background-image: -webkit-radial-gradient(#b8b7b7 30%, #ffffff00 20%);
  background-image: radial-gradient(#b8b7b7 30%, #ffffff00 20%);
  background-position: 0 0;
  background-size: 20px 20px;
  height: 200px;
  width: 120vw;
  -webkit-transform: rotate(-5deg);
          transform: rotate(-5deg);
  left: -50px;
  position: absolute;
  z-index: -1;
  margin-top: -16%; }
  @media screen and (max-width: 400px) {
    .stripe {
      display: none; } }
  .stripe.accent-blue {
    background-image: -webkit-radial-gradient(#A7DDE8 30%, #ffffff00 20%);
    background-image: radial-gradient(#A7DDE8 30%, #ffffff00 20%); }
  .stripe.accent-pink {
    background-image: -webkit-radial-gradient(#E51B5D 30%, #ffffff00 20%);
    background-image: radial-gradient(#E51B5D 30%, #ffffff00 20%); }
  .stripe.accent-orange {
    background-image: -webkit-radial-gradient(#F46E20 30%, #ffffff00 20%);
    background-image: radial-gradient(#F46E20 30%, #ffffff00 20%); }

.stripe-wrapper {
  margin: 10vw 0 200px; }
  .stripe-wrapper.sponsors {
    margin: 12vw 0 1vw; }

.floating-illustration {
  position: absolute;
  top: 10%;
  left: 0;
  right: 0; }
  @media screen and (max-width: 1023px) {
    .floating-illustration {
      display: none; } }
  .floating-illustration img {
    position: absolute;
    top: 0;
    right: 3%;
    width: 13vw;
    min-width: 150px;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: updown;
            animation-name: updown;
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease; }
  .floating-illustration.drone img {
    margin-top: 85vh; }
  .floating-illustration.clouds img {
    margin-top: 400px;
    left: 3%;
    right: auto; }
  .floating-illustration.relax img {
    margin-top: 1000px; }
  .floating-illustration.tree img {
    margin-top: -50px;
    left: 4%;
    right: auto; }
  .floating-illustration.eye img {
    margin-top: 750px; }
  .floating-illustration.computer {
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
    .floating-illustration.computer img {
      margin-top: -200px;
      right: 4%;
      left: auto; }
  .floating-illustration.hand-point {
    width: 100vw;
    margin-left: -15.5vw; }
    .floating-illustration.hand-point img {
      -webkit-animation-name: leftright;
              animation-name: leftright;
      left: 0; }
  .floating-illustration.lightbulb {
    width: 100vw;
    top: 70%; }
    .floating-illustration.lightbulb img {
      -webkit-animation-name: leftright;
              animation-name: leftright;
      right: 14vw;
      width: 15vw; }

@-webkit-keyframes updown {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes updown {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes leftright {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  50% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes leftright {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  50% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

