#challenges {
  position: relative;
  margin: $large-space 0;

  .section-heading {
    padding-top: 100px;
    margin-bottom: $small-space;
  }

  .section-intro {
    color: $light-gray;
    font-size: $subheading-font-size * 0.75;
    width: $max-width;
    max-width: 80vw;
    margin: $small-space auto;
  }

  .container {
    color: $light-gray;

    @include flex(row wrap, center, flex-start);
    @media screen and (max-width: 750px) {
      display: block;
      width: 100%;
    }
  }
}

.challenge {
  background-color: #fff;
  box-shadow: 0px 0px 15px 0px #e4e4e4;
  margin: 1vw;
  border-radius: 20px;
  width: 27vw;
  min-width: 300px;
  padding: 2vw;
  filter: alpha(opacity=60);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-self: stretch;
  transition: all 0.5s;
  cursor: auto;
  @include flex(column nowrap, flex-start, center);

  &:hover {
    transform: scale(1.05);
  }

  @media screen and (max-width: 750px) {
    display: block;
    width: 80%;
    margin: 0px auto;
    margin-top: 30px;
    padding: $large-space;
  }

  img {
    margin-bottom: $small-space;
    display: block;
    width: 50%;
    margin-left: auto;
    margin-right: auto;

  }

  h1 {
    font-size: $subheading-font-size;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: $small-space;
    font-weight: $heavy-font-weight;
  }

  p {
    font-size: $medium-font-size;
    text-align: center;
  }
}
