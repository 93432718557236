#projects {
  position: relative;

  .section-heading {
    padding-top: 100px;
    margin-bottom: $small-space;
  }

  .container {
    max-width: $max-width;
    margin: $large-space auto;
  }

  @media screen and (max-width: 750px) {
    .grid-item {
      width: 68vw;
    }
  }
}

.slick-track {
    @include flex(row nowrap, center, center);
}

.grid-item {
  box-shadow: 0px 0px 15px 0px #e4e4e4;
  border-radius: 20px;
  transition-duration: $transition-speed;
  color: white;
  padding: $small-space;
  margin: 3vh auto;
  cursor: pointer;
  width: 26vw;
  height: 100%;

  @include flex(column nowrap, center, center);
  &:hover {
    transform: scale(1.05);
  }

  p {
    text-align: center;
    margin: 0 1vw;
  }

  .title {
    font-weight: bold;
    font-size: $subheading-font-size;
    text-transform: uppercase;
    opacity: 1;
    margin: $small-space 0;
  }

  .text {
    font-size: $medium-font-size;
    margin-bottom: $small-space;
  }

}
