$navbar-logo-font-size: 36px;
$navbar-logo-image-width: 45px;
$navbar-logo-image-height: 40px;
$navbar-font-size: $base-font-size * 1.18;
$navbar-margin: 0.5vw;
$navbar-horizontal-padding: 15px;

#navbar {
  position: fixed;
  top: 0;
  z-index: 101;

  background-color: $white;
  height: 80px;
  width: 100vw;

  box-shadow: 0 5px 30px -5px rgba(0, 0, 0, 0.2);

  .container {
    @include flex(row nowrap, center, center);
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0 $small-space;
    list-style-type: none;
  }
}

#navbar-logo {
  flex: 1;
  margin-top: -0.5vh;
  font-size: $navbar-logo-font-size;
  padding: 0 $navbar-horizontal-padding 0 $navbar-horizontal-padding;

  a {
    @include flex(row nowrap, flex-start, center);
  }

  img {
    width: $navbar-logo-image-width;
    height: $navbar-logo-image-height;
  }
}

.menu {
  width: 100%;
  max-width: 1300px;
  display: flex;
  align-items: center;
  list-style-type: none;
  text-align: center;
  li {
    display: inline-block;
    vertical-align: middle;
  }
}

.navbar-item {
  margin: 10px $navbar-margin;
  padding: 0 $navbar-horizontal-padding 0 $navbar-horizontal-padding;
  font-size: $navbar-font-size;

  a {
    color: $light-gray;
  }

  &:hover a {
    color: $dark-green;
  }
}

.special-link a {
  color: $orange;
}

.apply-link a {
  color: $white;
  background-color: $dark-green;
  padding: 3px 6px;
  font-weight: bold;
  border-radius: 0.25rem;
  transition: $transition-speed all;
  display: inline-block;

  &:hover,
  &:focus {
    transform: scale(1.05);
    color: #fff;
  }
}

#navbar-apply {
  margin-left: $navbar-margin;
  padding: $navbar-horizontal-padding $navbar-margin * 1.5;
  font-size: $navbar-font-size;
  font-weight: $heavy-font-weight;
  transition: background-color $transition-speed;

  &:hover {
    background-color: $green;
  }
}

#title {
  color: $dark-green;
  margin-left: 1vw;
}

@media screen and (max-width: 1220px) {
  .menu {
    display: block;
  }

  .navbar-item {
    margin: $navbar-margin;
  }

  #navbar-logo {
    display: none;
  }

  .navbar-item.about-link {
    display: none;
  }
}
