#faq {
  position: relative;
  margin: $large-space $small-space;
  width: 70vw;
  margin: auto;
  font-size: 1.1rem;

  .section-heading {
    margin-bottom: $small-space;
  }

  .faq-plus {
    margin-right: 20px;
    width: 30px;
    height: 30px;
  }

  .section-subheading {
    margin-bottom: 25px;
    // cursor: pointer;
  }

  .faq-question, .faq-answer {
    text-align: left;
  }

  .faq-question {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
  }

  .faq-answer {
    margin-bottom: 15px;
    color: $light-gray;
    transition: max-height .5s ease-in-out;
    overflow: hidden;
  }

  .faq-container {
    margin: -$small-space/1.25;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .faq-item {
    padding: $small-space/1.25;
    flex-basis: 50%;

    @media screen and (max-width: 1023px) {
      flex-basis: 100%;
    }
  }
}
