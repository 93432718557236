#footer {
  position: relative;
  text-align: center;

  #footerImg {
    width: 100%;
    opacity: 0.8;
    background-image: url("svg/main-quad.svg");
    background-size: cover;
    background-position-x: center;
    height: 30.52vw; // h/w ratio of image * 100%
  }
}

.footer-item {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin: 0 15px 20px 0px;
  transition: 0.2s;

  &:nth-child(5) {
    margin-right: 0;
  }

  &:hover {
    transform: scale(1.05, 1.05);
  }

  img {
    width: 100%;
  }
}
