.apply-button {
  margin-top: $small-space / 2;
  background-color: $dark-green;
  padding: 0.5vh 2vw 0.5vh 2vw;
  border-radius: 5px;
  &:hover {
    transform: scale(1.05);
  }
}

#home {
  @include flex(column nowrap, flex-start, center);
  @media screen and (min-width: 450px) {
    height: 100vh;
    width: 100vw;
    min-height: calc(500px + 45vh);
    padding-top: 20vh;
  }
  @media screen and (max-width: 450px) {
    margin: $large-space 0;
  }

  img {
    max-height: 35vh;
    max-width: 80vw;
  }
  @media screen and (max-width: 450px) {
    padding-top: 15vh;
    img {
      margin-bottom: 25px;
    }
  }

  .logo {
    min-height: 45vh;
    @media screen and (max-width: 750px) {
      min-height: 20vh;
    }
  }

  #arm {
    height: 14vh;
    @media screen and (max-width: 450px) {
      height: 7vh;
    }
    margin-right: auto;
    margin-left: -1vw;
  }

  h1 {
    font-size: 8vh;
    font-weight: normal;
  }

  h2 {
    font-size: $base-font-size * 1.4;
    font-weight: $heavy-font-weight;
    color: $blue;
    max-width: 80vw;

    &.ended {
      font-style: italic;
    }

    @media screen and (max-width: 750px) {
      text-align: center;
    }
  }

  p {
    font-size: 25px;
  }

  #date-text {
    max-width: 90vw;
    text-align: center;
    color: $dark-green;
    font-size: $subheading-font-size;
    font-weight: bold;
    padding-bottom: 1vh;
  }
}

@-webkit-keyframes scale-in-center{0%{-webkit-transform:scale(0);transform:scale(0);opacity:1}100%{-webkit-transform:scale(1);transform:scale(1);opacity:1}}@keyframes scale-in-center{0%{-webkit-transform:scale(0);transform:scale(0);opacity:1}100%{-webkit-transform:scale(1);transform:scale(1);opacity:1}}
@-webkit-keyframes slide-in-left{0%{-webkit-transform:translateX(-1000px);transform:translateX(-1000px);opacity:0}100%{-webkit-transform:translateX(0);transform:translateX(0);opacity:1}}@keyframes slide-in-left{0%{-webkit-transform:translateX(-1000px);transform:translateX(-1000px);opacity:0}100%{-webkit-transform:translateX(0);transform:translateX(0);opacity:1}}
.scale-in-center{-webkit-animation:scale-in-center .5s cubic-bezier(.25,.46,.45,.94) both;animation:scale-in-center .5s cubic-bezier(.25,.46,.45,.94) both}
.slide-in-left{-webkit-animation:slide-in-left .5s cubic-bezier(.25,.46,.45,.94) both;animation:slide-in-left .5s cubic-bezier(.25,.46,.45,.94) both}
